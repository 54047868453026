@import '/public/styles/core';

.BillingCard {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1.5rem;

    @include from-mq-sm {
      flex-direction: row;
      align-items: center;
    }
  }

  .BillingCardPaymentDetails {
    display: flex;
    column-gap: 0.5rem;

    @include from-mq-sm {
      align-items: center;
    }
  }

  svg {
    margin-left: auto;
    width: 120px;
    height: auto;
  }
}
