// Constants
$header-panel-height: 4.5rem;
$bottom-panel-height: 4rem;

// Set paddings according to the grid
@mixin page-vertical-paddings {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include from-mq-sm {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include from-mq-md {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include from-mq-lg {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  @include from-mq-xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
