@import '/public/styles/core';

.ContentContainer {
  @include page-vertical-paddings;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  padding-top: 0 !important;
}

.Header {
  margin-top: 0 !important;
}

.Description {
  margin-bottom: 2.5rem;

  p {
    color: #3d3d3d;
  }
}

.VideoWrapper {
  position: relative;
  margin-top: 1.5rem;

  @include from-mq-sm {
    margin-top: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
