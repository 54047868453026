@import '/public/styles/core';

.ListOfFields {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #b8b8b8;
}

.Title {
  margin-bottom: 0.25rem;
}

.Description p {
  color: #5e5e5e;
}

.FieldLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.InputWrapper {
  flex: 1;
  margin-bottom: 0;
}

.Input {
  margin-right: 0.5rem;
}

.CustomFieldActionButton {
  margin-top: 0.25rem;
}
