@import '/public/styles/core';

.AccountSectionCard {
  padding: 2.5rem !important;
  row-gap: 1.5rem !important;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &.AccountSectionCardRow {
    flex-direction: column;
    row-gap: 1.5rem;

    @include from-mq-sm {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 2rem;
    }

    .AccountSectionCardHeader {
      max-width: min(165px, 100%);
    }
  }

  .AccountSectionCardHeader {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .AccountSectionCardTextCta {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @include from-mq-md {
        flex-direction: row;
        gap: 2rem;
      }

      .AccountSectionCardPrice {
        margin-bottom: 1rem;
      }
    }
  }

  .AccountSectionCardMain {
    .AccountSectionCardListHeading {
      margin-bottom: 1.5rem;
    }

    .AccountSectionCardList {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      margin-block: 0;
      list-style: none;
      row-gap: 0.5rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &.AccountSectionCardList2Columns,
      &.AccountSectionCardList3Columns {
        column-gap: 1.5rem;
        column-fill: balance;

        @include from-mq-md {
          display: block;
        }

        li {
          margin-bottom: 0.5rem;
          break-inside: avoid;
        }
      }

      &.AccountSectionCardList2Columns {
        column-count: 2;
      }

      &.AccountSectionCardList3Columns {
        column-count: 3;
      }

      li {
        display: flex;
        column-gap: 0.5rem;
      }
    }
  }
}
