@import '/public/styles/core';

.ContentContainer {
  @include page-vertical-paddings;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  padding-top: 0 !important;
}

.SimpleBar {
  max-height: 320px;
}

.Main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: 2.5rem;
}

.Header {
  margin-top: 0 !important;
}

.Description {
  margin-bottom: 2.5rem;

  p {
    color: #3d3d3d;
  }
}

// Card Header
.HeaderToggler {
  @include button-clear;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: var(--vfq-card-wrapper-border-radius-md);

  &.Disabled {
    color: #b8b8b8;

    .Icon {
      fill: #b8b8b8;
    }
  }

  &:focus {
    outline: 2px solid #141414;
  }
}

.Icon {
  margin-right: 1rem;
  flex-shrink: 0;
  fill: #eb1414;
}

.TitleContainer {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

// Form tab container
.FormTab {
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .ChecklistTeamCreationCard,
  .ChecklistTeamsListCard {
    padding: 0;
    row-gap: 0;
    border-radius: var(--vfq-card-wrapper-border-radius-md);
  }
}

.TabContent {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: max-height 0.5s linear;
  row-gap: 2rem;

  & > div > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  .FormGroup:last-child {
    margin-bottom: 0;
  }
}

.TabContentEnter,
.SubTitleEnter {
  max-height: 0;
}

.TabContentEnterActive {
  overflow: hidden;
  max-height: 650px;
}

.SubTitleEnterActive {
  overflow: hidden;
  max-height: 25px;
}

.TabContentExit {
  max-height: 650px;
}

.SubTitleExit {
  max-height: 25px;
}

.TabContentExitActive,
.SubTitleExitActive {
  overflow: hidden;
  max-height: 0;
}

.TeamListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;

  & > button {
    flex-shrink: 0;
  }
}
