@import '/public/styles/core';

.FormWrapper {
  width: 100%;

  .FormWrapperHeader {
    margin-bottom: 1.5rem;
    max-width: 100%;

    .ids-text {
      max-width: 100%;

      + .ids-text {
        margin-top: 0.5rem;
      }
    }
  }

  .FormWrapperFieldset {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 1.5rem;

    @include from-mq-sm {
      row-gap: 0.5rem;
    }

    &:not(.is-left-aligned) {
      justify-content: center;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    .FormWrapperActions {
      margin-bottom: 0;

      .ids-button-group {
        .ids-button {
          &:last-child {
            width: min(206px, 100%);

            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .PasswordField,
  .ids-field-wrapper {
    flex-basis: 100%;
    max-width: 100%;

    @include from-mq-sm {
      flex-basis: calc(50% - 0.75rem);
      max-width: calc(50% - 0.75rem);
    }

    &.is-fullwidth {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.is-centered {
      text-align: center;
    }

    &.is-right-aligned {
      text-align: right;
    }

    &.extra-margin {
      margin-bottom: 2rem;
    }

    &.is-stay-signed-in {
      .attr-injector {
        display: flex;
        justify-content: space-between;
      }
    }

    @include from-mq-sm {
      &:not(.is-fullwidth) {
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }

  .PasswordField {
    position: relative;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .TooltipContainer {
      position: absolute;
      top: calc(100% + 23px);
    }

    .ids-field-wrapper {
      margin-bottom: 0;

      .ids-helper {
        &.is-valid,
        &.is-invalid {
          margin-bottom: -18px;
        }
      }
    }
  }
}
