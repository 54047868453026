@import '/public/styles/core';

.SignUpAgreementCheckboxWrapper {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  label {
    cursor: pointer;
  }
}
