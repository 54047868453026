@mixin button-clear {
  align-items: unset;
  padding: 0;
  border: none;
  background: none;
  text-align: initial;
  box-shadow: none;
  cursor: pointer;
  appearance: none;

  &:disabled {
    cursor: not-allowed;
  }
}
